import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Cruise = () => (
  <Layout>
    <SEO title="Cruise" />
    <h1>Hi I'm Cruise</h1>
    <p>I was a junkyard dog. Adopted on March 6th, 2021</p>
    <div>
      <img src="https://res.cloudinary.com/angular/image/upload/c_scale,w_600/v1615769613/welovetonky/cruise.jpg" alt="Cruise" />
    </div>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default Cruise
